import { useSelector } from 'react-redux';
import { Route, Routes } from 'react-router-dom';
import { usePermissions } from '@/modules/permissions';
import { ProtectedRoute } from '@/components/PrivateRoute';
import {
  Warehouses,
  WarehousesEdit,
  WarehousesCreate,
  WarehousesView
} from '@/pages/warehouses';
import { getPlainCurrentUser } from '@/storeGetters';

export const WarehousesRoutes = () => {
  const permissions = usePermissions();
  const { isCustomer } = useSelector(getPlainCurrentUser);

  const hasWarehousesManagementPrivilege =
    permissions.hasWarehousesManagementPrivilege();

  return (
    <ProtectedRoute isAllowed={isCustomer && hasWarehousesManagementPrivilege}>
      <Routes>
        <Route path="/" element={<Warehouses />} />
      </Routes>
      <Routes>
        <Route path="/create" element={<WarehousesCreate />} />
      </Routes>
      <Routes>
        <Route path="/:warehouseId/edit" element={<WarehousesEdit />} />
      </Routes>
      <Routes>
        <Route path="/:warehouseId/view" element={<WarehousesView />} />
      </Routes>
    </ProtectedRoute>
  );
};
