import { TextField, Paper, PaperProps, PopperProps } from '@mui/material';
import { TextFieldProps } from '@mui/material/TextField';
import {
  forwardRef,
  useCallback,
  useEffect,
  useImperativeHandle,
  useLayoutEffect,
  useMemo,
  useRef,
  useState
} from 'react';
import { StyledEngineProvider, ThemeProvider } from '@mui/material/styles';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DesktopDatePicker as MUIDatePicker } from '@mui/x-date-pickers';
import { enUS, ru } from 'date-fns/locale';
import cn from 'classnames';
import { Typography } from '@/components/Typography';
import { ButtonIcon } from '@/components/ButtonIcon';
import { Icon } from '@/components/Icon';
import { useEventCallback } from '@/hooks';
import { DatePickerProps, DatePickerRef } from './DatePicker.types';
import theme from './DatePicker.theme';
import {
  formatDayOfWeek,
  getConstraintDateNumber,
  getInputValueFromDate,
  getTimeNumber,
  isValidDate,
  makeDateString,
  isMatch,
  isSameDay
} from './utils';
import { useUpdateKey } from './useUpdateKey';
import styles from './DatePicker.module.scss';

const DATE_RU = 'dd.MM.yyyy';
const DATE_EN = 'MM/dd/yyyy';
const DATE_PLACEHOLDER_RU = 'дд.мм.гггг';
const DATE_PLACEHOLDER_EN = '';

interface ArrowButtonProps {
  onClick: () => void;
}

function LeftArrowButton({ onClick }: ArrowButtonProps) {
  return (
    <ButtonIcon
      className={styles.arrowLeft}
      iconName="arrow-left"
      variant="small"
      size={16}
      onClick={onClick}
    />
  );
}

function RightArrowButton({ onClick }: ArrowButtonProps) {
  return (
    <ButtonIcon
      className={styles.arrowRight}
      iconName="arrow-right"
      variant="small"
      size={16}
      onClick={onClick}
    />
  );
}

function OpenPickerIcon() {
  return (
    <Icon
      className={styles.calendarIcon}
      iconName="calendar"
      height={16}
      width={16}
    />
  );
}

function PaperContent(props: PaperProps) {
  return (
    <Typography variant="body1Reg">
      <Paper className={styles.paper} {...props} />
    </Typography>
  );
}

const calendarComponents = {
  OpenPickerIcon,
  PaperContent,
  LeftArrowButton,
  RightArrowButton
};

const popperProps = { placement: 'bottom-start' } as Partial<PopperProps>;

export const DatePicker = forwardRef<DatePickerRef, DatePickerProps>(
  (
    {
      locale = 'ru',
      className = '',
      date = null,
      minDate,
      maxDate,
      onChange,
      onBlur,
      disabled = false,
      error = false,
      helperText,
      inputClassName = '',
      autoCorrect = true,
      returnEndOfDay = false
    },
    ref
  ) => {
    const min = useMemo(() => {
      if (minDate === null) {
        return getTimeNumber(new Date(1900, 0, 1), {
          returnStartOfDay: true
        })!;
      }

      return getTimeNumber(minDate ?? new Date(), {
        returnStartOfDay: true
      })!;
    }, [minDate]);
    const max = useMemo(
      () =>
        getTimeNumber(maxDate ?? new Date(2100, 11, 31), {
          returnEndOfDay: true
        })!,
      [maxDate]
    );
    const constraintDateNumber = useMemo(
      () =>
        getConstraintDateNumber(date, {
          min,
          max,
          autoCorrect,
          returnEndOfDay
        }),
      [date, min, max, autoCorrect, returnEndOfDay]
    );

    const constraintDateNumberRef = useRef(constraintDateNumber);
    const minRef = useRef(min);
    const maxRef = useRef(max);
    const inputValueRef = useRef<null | string>(null);

    const [value, setValue] = useState<number | null>(constraintDateNumber);
    // eslint-disable-next-line @typescript-eslint/naming-convention
    const [inputValue, _setInputValue] = useState<string>('');
    const [pickerKey, updatePickerKey] = useUpdateKey();

    const setInputValue = useCallback((inputtedValue: string) => {
      _setInputValue(inputtedValue);
      inputValueRef.current = inputtedValue;
    }, []);

    const [active, setActive] = useState(false);

    const onChangeDate = useEventCallback(onChange);

    const [format, placeholder] = useMemo(() => {
      if (locale === 'en') {
        return [DATE_EN, DATE_PLACEHOLDER_EN];
      }
      return [DATE_RU, DATE_PLACEHOLDER_RU];
    }, [locale]);

    useLayoutEffect(() => {
      setValue(constraintDateNumber);
      setInputValue(getInputValueFromDate(constraintDateNumber, format));
      constraintDateNumberRef.current = constraintDateNumber;
      minRef.current = min;
      maxRef.current = max;
    }, [
      constraintDateNumber,
      min,
      max,
      returnEndOfDay,
      autoCorrect,
      format,
      setInputValue
    ]);

    useImperativeHandle(
      ref,
      () => ({
        clearValue: () => setValue(null)
      }),
      []
    );

    const handleChange = useCallback(
      (newValue: Date | null, keyboardInputValue?: string) => {
        if (keyboardInputValue) {
          setInputValue(keyboardInputValue);
          return;
        }

        const preparedValue = getTimeNumber(newValue, { returnEndOfDay });

        setValue(preparedValue);
        setInputValue(getInputValueFromDate(preparedValue, format));

        onChangeDate(preparedValue ? new Date(preparedValue) : null);
      },
      [returnEndOfDay, format, onChangeDate, setInputValue]
    );

    useEffect(() => {
      if (!inputValueRef.current) {
        if (constraintDateNumberRef.current) {
          setValue(null);
          onChangeDate(null);
        }
        return;
      }

      if (!isMatch(inputValueRef.current, format)) {
        return;
      }

      const constraintValueNumber = getConstraintDateNumber(
        makeDateString(inputValueRef.current, format),
        {
          autoCorrect,
          min: minRef.current,
          max: maxRef.current,
          returnEndOfDay
        }
      )!;

      if (!isValidDate(constraintValueNumber)) {
        setValue(constraintDateNumberRef.current);
        setInputValue(
          getInputValueFromDate(constraintDateNumberRef.current, format)
        );
        updatePickerKey();
        return;
      }

      setValue(constraintValueNumber);
      const constraintInputValue = getInputValueFromDate(
        constraintValueNumber,
        format
      );

      if (inputValueRef.current !== constraintInputValue) {
        setInputValue(getInputValueFromDate(constraintValueNumber, format));
        updatePickerKey();
      }

      if (!isSameDay(constraintValueNumber, constraintDateNumberRef.current)) {
        onChangeDate(new Date(constraintValueNumber));
      }
    }, [
      inputValue,
      onChangeDate,
      format,
      returnEndOfDay,
      autoCorrect,
      updatePickerKey,
      setInputValue
    ]);

    const updateValueAndDateOnBlur = useCallback(() => {
      if (!inputValueRef.current) {
        return;
      }

      if (!isMatch(inputValueRef.current, format)) {
        setValue(constraintDateNumber);
        setInputValue(getInputValueFromDate(constraintDateNumber, format));
        updatePickerKey();
      }
    }, [format, constraintDateNumber, updatePickerKey, setInputValue]);

    const handleFocus = useCallback(() => setActive(true), []);
    const handleBlur = useCallback(() => {
      setActive(false);
      updateValueAndDateOnBlur();

      onBlur?.();
    }, [onBlur, updateValueAndDateOnBlur]);

    const renderInputCallback = useCallback(
      (props: TextFieldProps) => (
        <Typography variant="body1Reg">
          <TextField
            classes={{ root: active ? styles.active : '' }}
            onFocus={handleFocus}
            onBlur={handleBlur}
            {...props}
            type="date"
            error={error}
            helperText={helperText}
            inputProps={{
              ...props.inputProps,
              placeholder,
              'data-testid': 'datepicker-input'
            }}
          />
        </Typography>
      ),
      [active, handleBlur, handleFocus, placeholder, error, helperText]
    );

    return (
      <div className={cn(className, styles.DatePicker)}>
        <LocalizationProvider
          dateAdapter={AdapterDateFns}
          adapterLocale={locale === 'ru' ? ru : enUS}>
          <ThemeProvider theme={theme}>
            <StyledEngineProvider injectFirst>
              <MUIDatePicker
                key={pickerKey}
                className={inputClassName}
                maxDate={new Date(max)}
                minDate={new Date(min)}
                renderInput={renderInputCallback}
                value={value}
                onChange={handleChange}
                toolbarFormat={format}
                inputFormat={format}
                dayOfWeekFormatter={formatDayOfWeek}
                components={calendarComponents}
                PopperProps={popperProps}
                reduceAnimations
                showDaysOutsideCurrentMonth
                disabled={disabled}
              />
            </StyledEngineProvider>
          </ThemeProvider>
        </LocalizationProvider>
      </div>
    );
  }
);
