.button {
  position: relative;
  display: flex;
  justify-content: center;
  box-sizing: border-box;
  text-decoration: none;
  border: none;
  border-radius: var(--indent-xs);
  margin: 0;
  padding: 0;
  user-select: none;
  max-width: 100%;
}

.loading {
  cursor: progress;
}

.stretched {
  width: 100%;
}

.in {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: var(--indent-xs);
  min-height: inherit;
  text-align: center;
  box-sizing: border-box;
}

.stretched .in {
  justify-content: center;
}

.button-size-large .in {
  padding: var(--indent-xs) var(--indent-m);
}

.button-size-small .in {
  padding: var(--indent-xxs) var(--indent-s);
}

.button-size-large.iconOnly .in {
  padding: var(--indent-xs);
}

.button-size-small.iconOnly .in {
  padding: var(--indent-xxs);
}

.content {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}

.after,
.before {
  display: flex;
}

/* Primary button */
.button-mode-primary {
  background-color: var(--color-blue-500);
  color: var(--color-white);
}

.button-mode-primary svg path {
  stroke: var(--color-white);
}

.button-mode-primary.hover {
  background-color: var(--color-blue-400);
  color: var(--color-white);
}

.button-mode-primary.active {
  background-color: var(--color-blue-600);
  color: var(--color-white);
}

/* Secondary button */
.button-mode-secondary {
  background-color: var(--color-alpha-blue-50);
  color: var(--color-gray-900);
}

.button-mode-secondary svg path {
  stroke: var(--color-gray-900);
}

.button-mode-secondary.hover {
  background-color: var(--color-alpha-blue-100);
  color: var(--color-gray-900);
}

.button-mode-secondary.active {
  background-color: var(--color-gray-400);
  color: var(--color-gray-900);
}

/* Text button */
.button-mode-text {
  background-color: inherit;
  color: var(--color-gray-900);
}

.button-mode-text svg path {
  stroke: var(--color-gray-900);
}

.button-mode-text.hover {
  background-color: var(--color-alpha-blue-100);
  color: var(--color-gray-900);
}

.button-mode-text.active {
  background-color: var(--color-gray-400);
  color: var(--color-gray-900);
}

.button-mode-primary[disabled] {
  background-color: var(--color-gray-600);
  color: var(--color-white);
}

.button-mode-primary[disabled] svg path {
  stroke: var(--color-white);
}

.button-mode-secondary[disabled] {
  background-color: var(--color-alpha-blue-50);
  color: var(--color-gray-600);
}

.button-mode-secondary[disabled] svg path {
  stroke: var(--color-gray-600);
}

.button-mode-text[disabled] {
  color: var(--color-gray-600);
}

.button-mode-text[disabled] svg path {
  stroke: var(--color-gray-600);
}
