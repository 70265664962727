.button {
  cursor: pointer;
  border: none;
  background-color: transparent;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0;
  margin: 0;
}

.large,
.medium {
  border-radius: var(--indent-xxs);
}

.large {
  min-width: 32px;
  max-width: 32px;
  min-height: 32px;
  max-height: 32px;
}

.medium {
  min-width: 24px;
  max-width: 24px;
  min-height: 24px;
  max-height: 24px;
}

.small {
  min-width: 18px;
  max-width: 18px;
  min-height: 18px;
  max-height: 18px;
}

.extraSmall {
  width: 12px;
  height: 12px;
}

.large:hover,
.largeActive,
.medium:hover,
.mediumActive {
  background: var(--color-alpha-blue-100);
}

.large:active,
.medium:active {
  background: var(--color-alpha-blue-50);
}

.disabled {
  cursor: none;
  stroke: var(--color-gray-600);
  pointer-events: none;

  &:hover {
    cursor: default;
    background-color: transparent;
  }

  > svg {
    path {
      stroke: var(--color-gray-600);
    }
  }
}

.smallActive svg,
.extraSmallActive svg,
.small:hover svg,
.extraSmall:hover svg {
  stroke: var(--color-blue-500);

  > path {
    stroke: var(--color-blue-500);
  }
}
