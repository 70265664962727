export enum AppSection {
  Administration = 'administration',
  AdministrationCompanies = 'administrationCompanies',
  AdministrationUsers = 'administrationUsers',
  AdministrationSettings = 'administrationSettings',
  AdministrationInboxes = 'administrationInboxes',
  AdministrationModeration = 'administrationModeration',
  AllProcurements = 'allProcurements',
  MyOffice = 'myOffice',
  MyProcurements = 'myProcurements',
  MyProposals = 'myProposals',
  AccreditationRequests = 'accreditationRequests',
  PartnershipRequests = 'partnershipRequests',
  Needs = 'needs',
  SupplierManagement = 'supplierManagement',
  QualificationRequests = 'qualificationRequests',
  QualifiedSuppliers = 'qualifiedSuppliers',
  Accreditation = 'accreditation',
  PartnersCustomer = 'partnersCustomer',
  PartnersSupplier = 'partnersSupplier',
  ProfileEdit = 'profileEdit',
  Companies = 'companies',
  CompanyProfile = 'companyProfile',
  Messenger = 'messenger',
  MyCompany = 'myCompany',
  Warehouses = 'warehouses',
  CompanyEmployees = 'companyEmployees',
  CompanyRoleManagement = 'companyRoleManagement',
  CompanyFinance = 'companyFinance',
  Tools = 'tools',
  Routes = 'routes',
  Integrations = 'integrations',
  Tasks = 'tasks',
  Settings = 'settings',
  SettingsNotifications = 'settingsNotifications',
  SettingsGeneral = 'settingsGeneral',
  Goods = 'goods',
  InboxOrders = 'inboxOrders',
  OutboxOrders = 'outboxOrders',
  Cart = 'cart'
}
