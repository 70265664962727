import './polyfills';
import React from 'react';
import { createRoot } from 'react-dom/client';
import ReactGA from 'react-ga';
import ReactGTM from 'react-gtm-module';
import { YMInitializer } from 'react-yandex-metrika';
import { unstable_HistoryRouter as BrowserRouter } from 'react-router-dom';
import { ConfigProvider } from '@/ConfigProvider';

import configureStore from './store';
import customHistory from './customHistory';

import App from './app';

// NOTE: if you change file ./app
// don't forget to change bottom
// module.hot.accept('./app', () => {
//   const NextApp = require('./app-').default;
// otherwise hot reload will break.
//
// Don't create variable because show warning
//   WARNING in ./src/root.jsx
//   70:18-36 Critical dependency: the request of a dependency is an expression

import pasteImageReader from './pasteImageReader';

pasteImageReader(jQuery); // eslint-disable-line

const store = configureStore();

const app = document.createElement('div');
app.setAttribute('id', 'app');
app.setAttribute('class', 'root');
document.body.insertBefore(app, document.body.firstChild);

const modalContainer = document.createElement('div');
modalContainer.setAttribute('id', 'modal-container');

document.body.insertBefore(modalContainer, document.body.lastChild);

if (process.env.REACT_APP_GA_ENABLED) {
  ReactGA.initialize(process.env.REACT_APP_GA_TRACK_MESSENGER);
}

if (process.env.REACT_APP_GTM_ENABLED) {
  ReactGTM.initialize({ gtmId: process.env.REACT_APP_GTM_TRACK_MESSENGER });
}

const config = {
  noAccessPath: '/no-access'
};

const root = createRoot(app);

const render = (Component) => {
  root.render(
    <BrowserRouter history={customHistory}>
      <ConfigProvider initialConfig={config}>
        {process.env.REACT_APP_YM_ENABLED && (
          <YMInitializer
            accounts={[process.env.REACT_APP_YM_ACCOUNT]}
            options={{
              clickmap: true,
              trackLinks: true,
              accurateTrackBounce: true,
              webvisor: true,
              ecommerce: 'dataLayer'
            }}
            version="2"
          />
        )}
        <Component store={store} history={customHistory} />
      </ConfigProvider>
    </BrowserRouter>
  );
};

render(App);
