$swipeable-area-height: 54px;
$puller-width: 60px;
$puller-height: 7px;

.container {
  min-width: 530px;
  display: flex;
  flex-direction: column;
  gap: var(--indent-m);
  background: var(--color-white);
  padding: var(--indent-md) 0;
  box-shadow: 0 8px 24px rgb(30 34 46 / 12%), 0 4px 8px rgb(30 34 46 / 4%);
  border-radius: var(--indent-xs);
  overflow: hidden;
  position: relative;
  height: auto;
  font-size: 14px;
}

.swipeableArea {
  height: $swipeable-area-height;
  display: flex;
  justify-content: center;
  padding-top: var(--indent-ml);
}

.puller {
  width: $puller-width;
  height: $puller-height;
  border-radius: 15px;
  background-color: #d9d9d9;
}

.closeBtn {
  position: absolute;
  right: var(--indent-md);
  top: var(--indent-md);
}

.header {
  box-sizing: border-box;
  padding: 6px 0 6px var(--indent-md);
  width: calc(100% - var(--indent-xxl));

  .headerText {
    color: var(--color-gray-900);
    max-width: 100%;
  }
}

.footer {
  padding: 0 var(--indent-md);
  display: flex;
  align-items: center;
  column-gap: var(--indent-s);
  row-gap: var(--indent-s);
  flex-wrap: wrap;
}

.content {
  box-sizing: border-box;
  padding: 0 var(--indent-md);
  width: 100%;
  overflow-y: auto;
  overflow-x: hidden;
  color: var(--color-gray-900);
}

.content::-webkit-scrollbar {
  width: 8px;
  background-color: var(--color-gray-200);
}

.content::-webkit-scrollbar-thumb {
  background: rgb(12 35 98 / 20%);
  border-radius: 13px;
}

.actions {
  display: flex;
  align-items: center;
  gap: var(--indent-s);
}

.right {
  justify-content: right;
}

.center {
  justify-content: center;
}

.left {
  justify-content: left;
}

.space-between {
  justify-content: space-between;
}

.drawerContainer {
  background: var(--color-white);
  overflow: hidden;
  position: relative;
  height: auto;
  display: flex;
  flex-direction: column;

  .closeBtn {
    top: 0;
  }

  .footer {
    border-top: 1px solid var(--color-gray-400);
    padding: var(--indent-m);
    padding-bottom: 0;
  }

  .actions {
    width: 100%;
    display: grid;
    grid-auto-flow: column;
    gap: var(--indent-m);
    padding-bottom: var(--modal-actions);
  }
}

.drawerContent {
  display: flex;
  flex-direction: column;
  gap: var(--indent-m);
  position: relative;
  max-height: inherit;
  overflow: hidden;
  flex-grow: 1;
}

.root {
  :global(.MuiDialog-scrollPaper) {
    align-items: flex-start;
  }

  :global(.MuiDialog-container) {
    :global(.MuiPaper-root) {
      max-width: 80vw;
      max-height: 80vh;
    }
  }
}

.paper {
  :global(.MuiDialog-container) {
    :global(.MuiPaper-root) {
      max-width: 80vw;
      max-height: 92vh;
    }
  }
}

.drawer {
  border-top-left-radius: var(--indent-xs);
  border-top-right-radius: var(--indent-xs);
  box-shadow: 0 8px 24px rgb(30 34 46 / 12%), 0 4px 8px rgb(30 34 46 / 4%);
}

@media (width > 1280px) {
  .paper {
    :global(.MuiDialog-container) {
      :global(.MuiPaper-root) {
        max-width: 100%;
      }
    }
  }
}
