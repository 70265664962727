import cn from 'classnames';

import { Typography } from '@/components/Typography';
import { Tooltip } from '@/components/Tooltip';

import { Icon } from '@/components/Icon';

import { InputProps } from './inputLabel.types';

import styles from './inputLabel.module.scss';

export function InputLabel({
  children,
  text,
  required,
  definition,
  htmlFor,
  className,
  id
}: InputProps) {
  return (
    <label
      htmlFor={htmlFor}
      className={cn(styles.container, className)}
      data-testid="input-label"
      id={id}>
      <div className={styles.label}>
        <Typography variant="body2Reg" className={styles.labelContent} noWrap>
          <span className={cn({ [styles.required]: required })}>{text}</span>
        </Typography>
        {definition && (
          <Tooltip title={definition} maxWidth={240} align="left">
            <Icon iconName="question-fill" width={12} height={12} />
          </Tooltip>
        )}
      </div>
      {children}
    </label>
  );
}
