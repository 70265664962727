import {
  SyntheticEvent,
  useEffect,
  useState,
  useCallback,
  useRef,
  HTMLAttributes,
  useMemo
} from 'react';
import cn from 'classnames';
import {
  Autocomplete,
  AutocompleteClasses,
  AutocompleteRenderInputParams,
  TextField
} from '@mui/material';
import { StyledEngineProvider } from '@mui/material/styles';
import { Typography } from '@/components/Typography';
import { Loader } from '@/components/Loader';
import { useDebounce, useId } from '@/hooks';
import { isFunction } from '@/lib/utils';
import styles from './Typeahead.module.scss';
import { TypeaheadProps, Option } from './Typeahead.types';

const delay = 500;

function hasItemsToShow<TEntity>(items: TEntity[]) {
  return items.length > 0;
}

const classes: Partial<AutocompleteClasses> = {
  popper: styles.popper,
  input: styles.input,
  noOptions: styles.noOption
};

class ComponentPropsBuilder {
  private renderOptionsThrowPortal = false;

  setRenderOptionsThrowPortal(renderOptionsThrowPortal: boolean) {
    this.renderOptionsThrowPortal = renderOptionsThrowPortal;

    return this;
  }

  build() {
    return {
      popper: {
        disablePortal: !this.renderOptionsThrowPortal,
        popperOptions: {
          modifiers: [
            {
              name: 'offset',
              options: {
                offset: [0, 4]
              }
            }
          ]
        }
      }
    };
  }
}

const Loading = (
  <div className={styles.loader}>
    <Loader />
  </div>
);

export function Typeahead<TEntity>({
  containerClassName,
  className = '',
  label,
  required = false,
  provider,
  initialValue = '',
  placeholder = '',
  maxLength = 100,
  onChange,
  renderOption,
  getOptionLabel,
  onSelect,
  endAdornment,
  valid = true,
  onBlur,
  loadEmpty = false,
  disabled = false,
  noOptionsComponent = null,
  renderOptionsThrowPortal = false,
  id: idOverride,
  showLoader = false,
  errorText,
  autoFocus
}: TypeaheadProps<TEntity>) {
  const id = useId(idOverride);
  const [suggestions, setSuggestions] = useState<Option<TEntity>[]>([]);
  const [currentValue, setCurrentValue] = useState<string>(initialValue);
  const [open, setOpen] = useState(false);
  const [active, setActive] = useState(false);
  const [loading, setLoading] = useState(false);
  const resetInput = useRef(false);

  const handleFocus = useCallback(() => setActive(true), []);
  const handleBlur = useCallback(() => {
    if (onBlur && isFunction(onBlur)) {
      onBlur();
    }
    setActive(false);
  }, [onBlur]);

  const componentsProps = useMemo(
    () =>
      new ComponentPropsBuilder()
        .setRenderOptionsThrowPortal(renderOptionsThrowPortal)
        .build(),
    [renderOptionsThrowPortal]
  );

  useEffect(() => {
    setCurrentValue(initialValue);
  }, [initialValue]);

  const loadData = useCallback(
    (query: string) => {
      const loadSuggestions = async () => {
        setOpen(showLoader && !resetInput.current);
        setLoading(true);
        const items = (await provider.get(query)) || [];
        setSuggestions(items as Option<TEntity>[]);
        setLoading(false);
        setOpen(() => {
          if (noOptionsComponent) {
            return active && !resetInput.current;
          }

          return active && hasItemsToShow(items) && !resetInput.current;
        });
      };
      if ((loadEmpty || query !== '') && active) {
        loadSuggestions().catch((err) => console.log(err));
      }
    },
    [provider, active, loadEmpty, noOptionsComponent, showLoader]
  );

  const loadDataWithDebounce = useDebounce(loadData, delay);

  useEffect(() => {
    if ((loadEmpty && active) || currentValue !== '') {
      loadDataWithDebounce(currentValue);
    } else {
      setSuggestions([]);
      setOpen(false);
    }
  }, [currentValue, loadDataWithDebounce, active, loadEmpty]);

  const onInputChange = useCallback(
    (event: SyntheticEvent, value: string, reason: string) => {
      if (event === null) return;
      resetInput.current = reason === 'reset';
      setCurrentValue(value);
      if (typeof onChange === 'function') {
        onChange(value);
      }
    },
    [onChange]
  );

  const renderInputCallback = useCallback(
    (params: AutocompleteRenderInputParams) => (
      <Typography variant="body1Reg">
        <TextField
          {...params}
          placeholder={placeholder}
          InputProps={{
            ...params.InputProps,
            endAdornment,
            inputProps: { ...params.inputProps, maxLength }
          }}
          autoFocus={autoFocus}
          onFocus={handleFocus}
          onBlur={handleBlur}
        />
      </Typography>
    ),
    [placeholder, endAdornment, handleBlur, handleFocus, maxLength, autoFocus]
  );

  const renderOptionCallback = (
    props: HTMLAttributes<HTMLLIElement>,
    option: Option<TEntity>
  ) => (
    <li {...props} key={option.key} className={styles.option}>
      {renderOption ? (
        renderOption(option.value)
      ) : (
        <Typography
          className={styles.optionContent}
          Component="span"
          variant="body1Reg">
          {`${option.value}`}
        </Typography>
      )}
    </li>
  );

  const getOptionLabelCallback = useCallback(
    (option: Option<TEntity>) =>
      getOptionLabel ? getOptionLabel(option.value) : `${option.value}`,

    [getOptionLabel]
  );

  const onOpen = useCallback(
    (_event: SyntheticEvent) => {
      setOpen(hasItemsToShow(suggestions));
    },
    [suggestions]
  );

  const onClose = useCallback(
    (_event: SyntheticEvent, reason: string) => {
      if (
        reason === 'blur' ||
        reason === 'selectOption' ||
        reason === 'escape'
      ) {
        setOpen(false);
      } else {
        setOpen(hasItemsToShow(suggestions));
      }
    },
    [suggestions]
  );

  const onChangeCallback = useCallback(
    (
      _event: SyntheticEvent<Element, Event>,
      option: Option<TEntity> | null
    ) => {
      setOpen(false);
      if (typeof onSelect === 'function' && option) {
        onSelect(option.value);
      }
    },
    [onSelect]
  );

  const filterOptionCallback = useCallback(
    (options: Option<TEntity>[]) => options,
    []
  );

  const isOptionEqualToValueCallback = useCallback(
    (option: Option<TEntity>, value: Option<TEntity>) =>
      value.key === option.key,
    []
  );

  return (
    <div className={containerClassName}>
      {label && (
        <label htmlFor={id} className={styles.label}>
          <Typography className={cn(styles.labelContent)} noWrap>
            <span className={cn({ [styles.required]: required })}>{label}</span>
          </Typography>
        </label>
      )}

      <div
        className={cn(
          styles.autocomplete,
          className,
          !valid && styles.error,
          active && styles.active
        )}
        data-testid="typeahead">
        <StyledEngineProvider injectFirst>
          <Autocomplete
            id={id}
            disabled={disabled}
            onChange={onChangeCallback}
            isOptionEqualToValue={isOptionEqualToValueCallback}
            filterOptions={filterOptionCallback}
            classes={classes}
            onOpen={onOpen}
            onClose={onClose}
            renderInput={renderInputCallback}
            open={open}
            options={suggestions}
            onInputChange={onInputChange}
            fullWidth
            inputValue={currentValue}
            noOptionsText={noOptionsComponent}
            clearIcon={null}
            popupIcon={null}
            clearOnBlur={false}
            renderOption={renderOptionCallback}
            getOptionLabel={getOptionLabelCallback}
            componentsProps={componentsProps}
            loadingText={Loading}
            loading={loading}
          />
        </StyledEngineProvider>
      </div>

      {!valid && errorText && (
        <Typography variant="caption" className={styles.errorMessage}>
          {errorText}
        </Typography>
      )}
    </div>
  );
}
