import React from 'react';

export interface IFont {
  level: string | number;
  weight: string | number;
  lineHeight: string | number;
  Component?: React.ElementType;
}

export type Fonts =
  | 'h1'
  | 'h2'
  | 'h3'
  | 'body1Sb'
  | 'body1Med'
  | 'body1Reg'
  | 'body2Med'
  | 'body2Reg'
  | 'body3Reg'
  | 'caption';

export const FONTS: Record<Fonts, IFont> = {
  h1: { level: 'h1', weight: 1, lineHeight: 'h1', Component: 'h1' },
  h2: { level: 'h2', weight: 1, lineHeight: 'h2', Component: 'h2' },
  h3: { level: 'h3', weight: 1, lineHeight: 'h3', Component: 'h3' },
  body1Sb: { level: 1, weight: 1, lineHeight: 2 },
  body1Med: { level: 1, weight: 2, lineHeight: 2 },
  body1Reg: { level: 1, weight: 3, lineHeight: 1 },
  body2Med: { level: 2, weight: 2, lineHeight: 3 },
  body2Reg: { level: 2, weight: 3, lineHeight: 3 },
  body3Reg: { level: 4, weight: 3, lineHeight: 3 },
  caption: { level: 3, weight: 3, lineHeight: 3 }
};
