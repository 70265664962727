import assert from 'assert';
import { Map } from 'immutable';
import { createSelector } from 'reselect';
import { prop, isNil, curry } from 'ramda';
import { isType } from 'core/lib';
import {
  isLoading as isLoadingData,
  isLoadedOnce as isLoadedOnceData
} from 'core/data/loaderList';
import {
  isOwner,
  getMembersWithoutOwner,
  findMember
} from 'core/data/light/channel';
import {
  haveActivatedMS,
  haveAdminMR,
  haveParticipantMR,
  haveEditorMR,
  haveReaderMR
} from 'core/data/light/channelMember';
import {
  makePartList,
  getDataPart as getDataPartPL,
  getSelectedList as getSelectedListPL
} from 'core/data/partList';
import { isEnabledFilterById as isEnabledFilterByIdWP } from 'core/data/whereParams';
import { makeFilterOptions } from 'core/data/filter/filterOptions';
import { findItem } from 'core/data/configs';
import { getValue } from 'core/data/configItem';
import {
  getWhereParams as getWhereParamsRM,
  getPaginationQuery as getPaginationQueryRM,
  getSortBy as getSortByRM
} from 'core/data/requestMany';

import { getCurrEmplId } from '@/components/ChatWidget/getter';
import { getBadgeCount, getBadgeUnmutedCount } from '@/lib/utils';
import {
  getCities,
  getCountries,
  getExtendedUser,
  getRegions,
  getResponsibleUsers
} from './storeGetters';

export const getList = curry((name, state) => {
  const list = prop(name, state.get('lists'));
  return isNil(list) ? makePartList() : list;
});

export const getSelectedList = (label, state) =>
  getSelectedListPL(getList(label, state));
export const getDataPart = (label, state) =>
  getDataPartPL(getList(label, state));

export const getLoaderList = (state) => state.get('loaderList');
export const isLoading = (label, state) =>
  isLoadingData(label, getLoaderList(state));
export const isLoadedOnce = (label, state) =>
  isLoadedOnceData(label, getLoaderList(state));

export const getUserConfigs = (state) => state.get('userConfigs');
export const getRequestMany = (label, state) =>
  getValue(findItem(label, 'requestMany', getUserConfigs(state)));
export const getWhereParams = (label, state) =>
  getWhereParamsRM(getRequestMany(label, state));
export const getPaginationQuery = (label, state) =>
  getPaginationQueryRM(getRequestMany(label, state));
export const getSortBy = (label, state) =>
  getSortByRM(getRequestMany(label, state));
export const isEnabledFilterById = (label, state) =>
  isEnabledFilterByIdWP(getWhereParams(label, state));
export const getHiddenColumns = (label, state) =>
  getValue(findItem(label, 'excludedColumns', getUserConfigs(state)));

export const getFilterOptions = (label, state) =>
  state.get('filterOptions')[label] || makeFilterOptions();

export const getChannel = (state) => state.get('channel').channel;
export const getChannelId = (state) => state.get('channel').channel.id;
export const getChannelName = (state) => state.get('channel').channel.name;
export const getChannelMode = (state) => state.get('channel').mode;
export const isChannelModeView = (state) =>
  state.get('channel').mode === 'view';
export const isChannelModeSearch = (state) =>
  state.get('channel').mode === 'search';
export const isChannelModeBookmarks = (state) =>
  state.get('channel').mode === 'bookmarks';
export const getChannelMembers = (state) =>
  getMembersWithoutOwner(state.get('channel').channel);
export const getAllChannelMembers = (state) =>
  state.get('channel').channel.members;
export const getChannelArchived = (state) =>
  state.get('channel').channel.archived;
export const getChannelNotificationOff = (state) =>
  state.get('channel').channel.notificationOff;
export const checkCurrentMemberHaveActivedMS = (state) => {
  const employeeId = state.getIn(['user', 'user', 'employeeId']);
  const member = findMember(getChannel(state), employeeId);
  if (isNil(member)) return false;
  return haveActivatedMS(member);
};
export const getCurrentMember = (state) => {
  const employeeId = state.getIn(['user', 'user', 'employeeId']);
  return findMember(getChannel(state), employeeId);
};

export const isDemoAccount = (state) =>
  state.getIn(['user', 'user', 'type']) === 'demo';

export const getIsUserUploaded = (state) =>
  state.getIn(['user', 'user', 'isUploaded']);

const getUser = (state) => state.getIn(['user', 'user']);
const getUserStatus = (state) => state.getIn(['user', 'user', 'status']);

export const getCurrentUserStatus = createSelector(
  [getUserStatus],
  (status) => {
    if (status) return status.toJS();
    return null;
  }
);

/**
 * @deprecated use getPlainCurrentUser instead
 */
export const getCurrentUser = createSelector([getUser], (user) => user);

export const getPlainCurrentUser = getExtendedUser;

export const getCurrentUserId = (state) => state.getIn(['user', 'user', 'id']);

export const isAuth = (state) =>
  getIsUserUploaded(state) && !isNil(getCurrentUserId(state));

export const getCurrentEmployeeId = (state) =>
  state.getIn(['user', 'user', 'employeeId']);

export const getCurrentUserEmail = (state) =>
  state.getIn(['user', 'user', 'email']);

export const getCurrentUserLanguage = (state) =>
  state.getIn(['user', 'user', 'language']);

export const getCurrentUserAvatar = (state) =>
  state.getIn(['user', 'user', 'avatar']);

export const getCurrentUserType = (state) =>
  state.getIn(['user', 'user', 'type']);

export const getCurrentCompanyId = (state) => state.getIn(['company', 'id']);
export const getCurrentCompanyEmail = (state) =>
  state.getIn(['company', 'email']);

export const getChatBadgeCount = (state) =>
  getBadgeCount(state.getIn(['chat', 'chatBadgeCount']));
export const getChatUnmutedBadgeCount = (state) =>
  getBadgeUnmutedCount(state.getIn(['chat', 'chatBadgeCount']));

/**
 * @deprecated use getPlainCompany instead
 */
export const getCompany = (state) => state.get('company');

export const getPlainCompany = createSelector([getCompany], (company) =>
  company.toJS()
);

export const getCompanyId = (state) => state.getIn(['company', 'id']);
export const getCompanyOwnerId = (state) =>
  state.getIn(['company', 'ownerUserId']);
const isCompanyUploaded = (state) => state.getIn(['company', 'isUploaded']);
export const isUserNotHaveCompany = (state) =>
  isCompanyUploaded(state) && isNil(getCompanyId(state));

export const isCurrentUserOwnerCompany = (state) =>
  getCompanyOwnerId(state) === getCurrentUserId(state);

export const getBadges = createSelector(
  [(state) => state.get('badges')],
  (badges) => badges.toJS()
);

export const getAllPurchaseRequestsNotify = (state) =>
  state.getIn(['badges', 'allPurchaseRequests', 'badgesCount']);
export const getMyPurchaseRequestsNotify = (state) =>
  state.getIn(['badges', 'myPurchaseRequests', 'badgesCount']);
export const getMyPurchaseResponsesNotify = (state) =>
  state.getIn(['badges', 'myPurchaseResponses', 'badgesCount']);
export const getResponsePurchaseRequestNotify = (state) =>
  state.getIn(['badges', 'responsePurchaseRequest', 'badgesCount']);
export const getInboundMessagesNotify = (state) =>
  state.getIn(['badges', 'inboundMessages', 'badgesCount']);
export const getModerationMessagesNotify = (state) =>
  state.getIn(['badges', 'moderationMessages', 'badgesCount']);
export const getInboxAccreditationRequestsNotify = (state) =>
  state.getIn(['badges', 'inboxAccreditationRequests', 'badgesCount']);
export const getOutboxAccreditationRequestsNotify = (state) =>
  state.getIn(['badges', 'outboxAccreditationRequests', 'badgesCount']);
export const getInboxQualificationRequestsNotify = (state) =>
  state.getIn(['badges', 'inboxQualificationRequest', 'badgesCount']);
export const getOutboxQualificationRequestsNotify = (state) =>
  state.getIn(['badges', 'outboxQualificationRequest', 'badgesCount']);
export const getInboxOrdersNotify = (state) =>
  state.getIn(['badges', 'inboxOrders', 'badgesCount']);
export const getOutboxOrdersNotify = (state) =>
  state.getIn(['badges', 'outboxOrders', 'badgesCount']);
export const getAllPurchaseRequestsBadges = (state) =>
  state.getIn(['badges', 'allPurchaseRequests', 'badges']);
/**
 * @deprecated use getPlainMyPurchaseRequestsBadges instead
 */
export const getMyPurchaseRequestsBadges = (state) =>
  state.getIn(['badges', 'myPurchaseRequests', 'badges']);
export const getPlainMyPurchaseRequestsBadges = createSelector(
  [getMyPurchaseRequestsBadges],
  (badges) => badges?.toJS() ?? []
);
/**
 * @deprecated use getPlainMyPurchaseResponsesBadges instead
 */
export const getMyPurchaseResponsesBadges = (state) =>
  state.getIn(['badges', 'myPurchaseResponses', 'badges']);
export const getPlainMyPurchaseResponsesBadges = createSelector(
  [getMyPurchaseResponsesBadges],
  (badges) => badges?.toJS() ?? []
);
export const hasMyPurchaseResponsesBadge = createSelector(
  [getPlainMyPurchaseResponsesBadges, (state, responseId) => responseId],
  (badges, responseId) => badges.some((badge) => badge.typeId === responseId)
);
/**
 * @deprecated use getPlainResponsePurchaseRequestBadges instead
 */
export const getResponsePurchaseRequestBadges = (state) =>
  state.getIn(['badges', 'responsePurchaseRequest', 'badges']);
export const getPlainResponsePurchaseRequestBadges = createSelector(
  [getResponsePurchaseRequestBadges],
  (badges) => badges?.toJS() ?? []
);
export const hasResponsePurchaseRequestBadge = createSelector(
  [getPlainResponsePurchaseRequestBadges, (state, responseId) => responseId],
  (badges, responseId) => badges.some((badge) => badge.typeId === responseId)
);

/**
  @typedef {Object} InboundMsgBadge
  @property {string} type
  @property {string} status
  @property {string} typeId
  @property {string} userId
  @property {string} companyId
*/
/**
  @returns {Array<InboundMsgBadge>}
*/
export const getInboundMessagesBadges = (state) =>
  state.getIn(['badges', 'inboundMessages', 'badges'])?.toJS() ?? [];

/**
 @typedef {Object} ModerationMessages
 @property {string} type
 @property {string} status
 @property {string} typeId
 @property {string} userId
 @property {string} companyId
 */
/**
 @returns {Array<ModerationMessages>}
 */
export const getModerationMessagesBadges = (state) =>
  state.getIn(['badges', 'moderationMessages', 'badges'])?.toJS() ?? [];

/**
 @typedef {Object} InboxAccreditationRequestBadge
 @property {string} type
 @property {string} status
 @property {number} typeId
 @property {number} userId
 @property {number} companyId
 */
/**
 @returns {Array<InboxAccreditationRequestBadge>}
 */
export const getInboxAccreditationRequestsBadges = (state) =>
  state.getIn(['badges', 'inboxAccreditationRequests', 'badges'])?.toJS() ?? [];

/**
 @typedef {Object} OutboxAccreditationRequestBadge
 @property {string} type
 @property {string} status
 @property {number} typeId
 @property {number} userId
 @property {number} companyId
 */
/**
 @returns {Array<OutboxAccreditationRequestBadge>}
 */
export const getOutboxAccreditationRequestsBadges = (state) =>
  state.getIn(['badges', 'outboxAccreditationRequests', 'badges'])?.toJS() ??
  [];

/**
 @typedef {Object} InboxOrderBadge
 @property {string} type
 @property {string} status
 @property {number} typeId
 @property {number} userId
 @property {number} companyId
 */
/**
 @returns {Array<InboxOrderBadge>}
 */
export const getInboxOrdersBadges = (state) =>
  state.getIn(['badges', 'inboxOrders', 'badges'])?.toJS() ?? [];

/**
 @typedef {Object} OutboxOrderBadge
 @property {string} type
 @property {string} status
 @property {number} typeId
 @property {number} userId
 @property {number} companyId
 */
/**
 @returns {Array<OutboxOrderBadge>}
 */
export const getOutboxOrdersBadges = (state) =>
  state.getIn(['badges', 'outboxOrders', 'badges'])?.toJS() ?? [];

export const hasInboxOrderBadge = createSelector(
  [getInboxOrdersBadges, (state, orderId) => orderId],
  (badges, orderId) => badges.some((badge) => badge.typeId === orderId)
);

/**
  @returns {Array<import('./storeGetters.types').QualificationRequestBadge>}
*/
export const getInboxQualificationRequestBadges = (state) =>
  state.getIn(['badges', 'inboxQualificationRequest', 'badges'])?.toJS() ?? [];

/**
  @returns {Array<import('./storeGetters.types').QualificationRequestBadge>}
*/
export const getOutboxQualificationRequestBadges = (state) =>
  state.getIn(['badges', 'outboxQualificationRequest', 'badges'])?.toJS() ?? [];

const getStorageSize = (state) => state.getIn(['storage', 'storageSize']);
const getMaxStorageSize = (state) => state.getIn(['storage', 'maxStorageSize']);
export const haveFreeStorageSpace = createSelector(
  [getStorageSize, getMaxStorageSize],
  (size, maxSize) => size < maxSize
);

export const getCurrentPurchaseRequest = (state) =>
  state.getIn(['purchaseRequests', 'request']);
export const getCurrentPurchaseRequestMemo = createSelector(
  [getCurrentPurchaseRequest],
  (purchaseRequest) => purchaseRequest.toJS()
);

export const isCompanyOwner = (state) =>
  state.getIn(['company', 'ownerUserId']) ===
  state.getIn(['user', 'user', 'id']);

export const checkIfCurrentUserOwnerChannel = (state) => {
  const employeeId = state.getIn(['user', 'user', 'employeeId']);
  return isOwner(state.get('channel').channel, employeeId);
};

export const checkIfCurrentUserIsAdmin = (state) => {
  const currentMember = getCurrentMember(state);
  return currentMember ? haveAdminMR(currentMember) : false;
};

export const checkIfCurrentUserIsParticipant = (state) => {
  const currentMember = getCurrentMember(state);
  return currentMember ? haveParticipantMR(currentMember) : false;
};

export const checkIfCurrentUserIsEditor = (state) => {
  const currentMember = getCurrentMember(state);
  return currentMember ? haveEditorMR(currentMember) : false;
};

export const checkIfCurrentUserIsReader = (state) => {
  const currentMember = getCurrentMember(state);
  return currentMember ? haveReaderMR(currentMember) : false;
};

export const channelMessage = (state) => {
  const {
    messages,
    search: searchParams,
    bookmarkedIds
  } = state.get('channel');
  const isSearchMode = isChannelModeSearch(state);
  const isBookmarksMode = isChannelModeBookmarks(state);

  if (isSearchMode && searchParams) {
    const { search, from, to } = searchParams;
    let result = messages.filter((msg) => isType('MsgUser', msg));

    // Search by text
    if (search) {
      result = result.filter(({ msg }) =>
        msg.toLowerCase().includes(searchParams.search.toLowerCase())
      );
    }
    // Search by from
    if (from) {
      const searchDate = new Date(from);
      result = result.filter((message) => {
        const messageDate = new Date(message.time);
        return messageDate > searchDate;
      });
    }
    // Search by to
    if (to) {
      const searchDate = new Date(to);
      result = result.filter((message) => {
        const messageDate = new Date(message.time);
        return messageDate < searchDate;
      });
    }

    return result;
  }

  if (isBookmarksMode) {
    return messages.filter(({ id }) => bookmarkedIds.includes(id));
  }

  return messages;
};

export const getTempField = (state, name, defaultValue) =>
  isNil(state.get('temporary')[name])
    ? defaultValue
    : state.get('temporary')[name];

export const getTempFilesForRemove = (state) =>
  getTempField(state, 'filesToRemove', []);

export const getTempMemberRoles = (state) =>
  getTempField(state, 'membersRoles', {});
export const getTempExcludedMembers = (state) =>
  getTempField(state, 'excludeMembers', []);
export const getTempInvitedMembers = (state) =>
  getTempField(state, 'inviteMembers', []);

export const getDraftMessage = (state) => {
  const { id } = getChannel(state);
  return getTempField(state, id, '');
};

export const getUsersForDialogs = (state) =>
  (state.getIn(['chat', 'dialogs'])?.toJS() ?? [])
    .filter((dialog) => isType('Dialog', dialog))
    .filter(
      (dialog) =>
        !(
          (dialog.firstName === 'Support' && dialog.lastName === 'Goodwix') ||
          (dialog.firstName === 'Goodwix' && dialog.lastName === 'Support') ||
          (dialog.firstName === 'Пользователей' &&
            dialog.lastName === 'Поддержка') ||
          (dialog.firstName === 'Поддержка' &&
            dialog.lastName === 'Пользователей') ||
          (dialog.firstName === 'Goodwix' && dialog.lastName === 'Admin') ||
          (dialog.firstName === 'Admin' && dialog.lastName === 'Goodwix') ||
          (dialog.firstName === 'Администратор' &&
            dialog.lastName === 'Goodwix') ||
          (dialog.firstName === 'Goodwix' &&
            dialog.lastName === 'Администратор')
        )
    );

export const getCurrentUserBookmarkedMsg = (state) => {
  const employeeId = getCurrentEmployeeId(state);
  const messages = channelMessage(state);
  const userMessages = messages.filter((msg) => isType('MsgUser', msg));
  return userMessages.filter((message) =>
    message.bookmarkedBy.includes(employeeId)
  );
};

export const methodCheckIfOwnMsg = (state) => (msg) => {
  const employeeId = state.getIn(['user', 'user', 'employeeId']);
  return employeeId === msg.employeeId;
};

export const methodCheckIfBookmarkMsg = (state) => (msg) => {
  const employeeId = state.getIn(['user', 'user', 'employeeId']);

  if (isType('MsgUser', msg)) {
    return msg.bookmarkedBy.includes(employeeId);
  }

  return false;
};

export const methodCheckIfCurrUserMsgIsRead = (state) => (msg) => {
  const channel = getChannel(state);
  if (!methodCheckIfOwnMsg(state)(msg)) return true;
  return !channel.unreadMessagesId.includes(msg.id);
};

export const getContacts = (state) =>
  state.getIn(['contacts', 'contactsForChannel'])?.toJS() ?? [];

export const methodCheckIfAllContactsInDialog = (state) => {
  const members = getAllChannelMembers(state);
  const allContacts = getContacts(state);
  const activatedMembersId = members
    .filter(haveActivatedMS)
    .map((member) => member.employeeId);
  const contacts = allContacts.filter(
    (user) => !activatedMembersId.includes(user.employeeId)
  );

  return contacts.length === 0;
};

export const getAttachFiles = (state) => state.get('channel').files;

export const getModalManager = (state) => state.get('modal');

const emptyMap = new Map();

// NOTE: this getters returns unsent comments for ALL users!
export const doNotUseGetOrderUnsentComments = (state) =>
  state.getIn(['orders', 'unsentComments']);
export const doNotUseGetOrdersUnsentRequestsComments = (state) =>
  state.getIn(['purchaseRequests', 'unsentComments']);

export const getOrdersUnsentComments = (state) => {
  const currentEmployeeId = getCurrEmplId(state);
  const unsentComments = doNotUseGetOrderUnsentComments(state);

  assert(!isNil(currentEmployeeId));

  return unsentComments.get(currentEmployeeId.toString()) || emptyMap;
};

export const getUnsentRequestsComments = (state) => {
  const currentEmployeeId = getCurrEmplId(state);
  const unsentComments = doNotUseGetOrdersUnsentRequestsComments(state);

  assert(!isNil(currentEmployeeId));

  return unsentComments.get(currentEmployeeId.toString()) || emptyMap;
};

export const getPurchaseRequestError = (state) =>
  state.getIn(['purchaseRequests', 'error']).toJS();

export const getResponseError = (state) =>
  state.getIn(['purchaseRequests', 'responseError'])?.toJS();

export const getCurrentUserSoundNotificationsStatus = (state) =>
  state.getIn(['user', 'user', 'soundNotifications']) ?? true;

export const getPlainCountries = getCountries;
export const getPlainRegions = getRegions;
export const getPlainCities = getCities;
export const getPlainResponsibleUsers = getResponsibleUsers;
