import React from 'react';
import cn from 'classnames';
import { Tappable, TappableProps } from '@/components/Tappable';
import { Typography } from '@/components/Typography';
import styles from './Button.module.css';

export interface UIButtonProps {
  mode?: 'primary' | 'secondary' | 'text';
  size?: 'small' | 'large';
  stretched?: boolean;
  before?: React.ReactNode;
  after?: React.ReactNode;
  loading?: boolean;
  contentStyles?: string;
}

export interface ButtonProps
  extends Omit<TappableProps, 'size'>,
    UIButtonProps {}

interface ButtonTypographyProps {
  size: ButtonProps['size'];
  className?: string;
  children?: ButtonProps['children'];
}
function ButtonTypography({ size, ...restProps }: ButtonTypographyProps) {
  switch (size) {
    case 'small':
      return <Typography variant="body2Reg" {...restProps} />;
    default:
      return <Typography variant="body1Reg" {...restProps} />;
  }
}

export function Button({
  size = 'large',
  mode = 'primary',
  stretched = false,
  children,
  before,
  after,
  getRootRef,
  Component = 'button',
  loading,
  onClick,
  stopPropagation = true,
  contentStyles,
  ...restProps
}: ButtonProps) {
  const hasIconOnly = !children && Boolean(after) !== Boolean(before);

  return (
    <Tappable
      data-testid="button"
      hoverMode={styles.hover}
      activeMode={styles.active}
      {...restProps}
      Component={restProps.href ? 'a' : Component}
      onClick={loading ? undefined : onClick}
      focusVisibleMode="outside"
      stopPropagation={stopPropagation}
      className={cn(
        restProps.className,
        styles.button,
        styles[`button-mode-${mode}`],
        styles[`button-size-${size}`],
        stretched && styles.stretched,
        loading && styles.loading,
        hasIconOnly && styles['single-icon'],
        !children && styles.iconOnly
      )}
      getRootRef={getRootRef}>
      {/* {loading && <div>Сюда можно добавить loader</div>} */}
      <div className={styles.in}>
        {before && (
          <span className={styles.before} role="presentation">
            {before}
          </span>
        )}
        {children && (
          <ButtonTypography
            size={size}
            className={cn(styles.content, contentStyles)}>
            {children}
          </ButtonTypography>
        )}
        {after && (
          <span className={styles.after} role="presentation">
            {after}
          </span>
        )}
      </div>
    </Tappable>
  );
}
