export class LocalStorageProxy {
  store;

  constructor(store) {
    const userId = localStorage.getItem('userId');
    this.store = userId ? `${userId}-${store}` : `${store}`;
  }

  get(query) {
    let result;
    const store = localStorage.getItem(this.store);
    if (!store) {
      result = [];
    } else if (!query) {
      result = JSON.parse(store);
    } else {
      const regex = new RegExp(query, 'i');
      result = JSON.parse(store).filter((item) => item.match(regex));
    }
    return result;
  }

  add(item, maxCount) {
    if (item) {
      const items = this.get();
      if (items.length === 0 || (items.length !== 0 && !items.includes(item))) {
        items.push(item);
      }
      if (maxCount && items.length > maxCount) {
        items.shift();
      }
      this.save(items);
      return true;
    }

    return false;
  }

  save(items) {
    localStorage.setItem(this.store, JSON.stringify(items));
  }
}
