import { fromJS } from 'immutable';
import { makeBadge } from 'core/data/badge';

const initState = () =>
  fromJS({
    inviteSupplier: 0,
    inviteCustomer: 0,
    ordersSuppliers: 0,
    ordersCustomers: 0,
    suppliersAttaches: 0,
    customersAttaches: 0,
    supplierPrice: 0,
    ordersSuppliersComments: 0,
    ordersCustomersComments: 0,
    purchaseRequestComments: 0,
    invitePartner: 0,
    allPurchaseRequests: makeBadge(),
    myPurchaseRequests: makeBadge(),
    myPurchaseResponses: makeBadge(),
    responsePurchaseRequest: makeBadge(),
    inboundMessages: makeBadge(),
    moderationMessages: makeBadge(),
    inboxAccreditationRequests: makeBadge(),
    outboxAccreditationRequests: makeBadge(),
    inboxQualificationRequest: makeBadge(),
    outboxQualificationRequest: makeBadge(),
    inboxOrders: makeBadge(),
    outboxOrders: makeBadge()
  });

const setCount = (state, { type, count }) => state.set(type, count);
const setBadge = (state, { type, badge }) =>
  state.set(type, fromJS(makeBadge(badge)));

const deleteBadgeBySection = (state, { section, typeId }) => {
  const jsState = state.toJS();
  const sectionBadges = jsState[section];

  const newBadges = sectionBadges.badges.filter(
    (badge) => badge.typeId !== typeId
  );

  const newState = {
    ...jsState,
    [section]: {
      ...jsState[section],
      badges: newBadges,
      badgesCount: newBadges.length
    }
  };

  return fromJS(newState);
};

// eslint-disable-next-line @typescript-eslint/default-param-last
const badgeReducer = (state = initState(), action) => {
  switch (action.type) {
    case 'BADGE:SET_BADGE_COUNT':
      return fromJS(action.payload);
    case 'BADGE:SET_BADGE_COUNT:ONE':
      return setCount(state, action.payload);
    case 'BADGE:SET_BADGE:ONE':
      return setBadge(state, action.payload);
    case 'DELETE:BADGE:BY:SECTION:TYPE_ID':
      return deleteBadgeBySection(state, action.payload);
    default:
      return state;
  }
};

export default badgeReducer;
