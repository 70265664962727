import { bindActionCreators } from 'redux';
import { toast } from '@/modules/toast';
import { filesLoadHandler } from '@/useCases/files';
import { showModal } from '@/action-creators/modal';
import * as actions from '../actions';
import * as getters from '../getters';
import * as storage from '../storage';
import * as commonGetters from '../../../../../storeGetters';
import * as cwGetters from '../../../getter';
import * as waitingMessagesActions from '../../waitingMessages/actions';
import * as waitingMessagesGetters from '../../waitingMessages/getters';
import * as waitingMessagesCases from '../../waitingMessages/useCases';
import * as messagesGetters from '../../messages/getters';
import * as messagesCases from '../../messages/useCases';
import { filesErrorCase } from './filesError';
import { applyState } from '../../utils';
import { attachFilesFromStorageCase } from './attachFilesFromStorage';
import { attachLocalFilesCase } from './attachLocalFiles';
import customHistory from '../../../../../customHistory';
import { removeUnsentFileCase } from './removeUnsentFile';
import { changeTextCase } from './changeText';
import { attachFilesFromStorageModalCase } from './attachFilesFromStorageModal';
import { editMessageCase } from './editMessage';

function setFocusOnInputMessage() {
  $('#message-input').click();
}

const filesError = (key, params) =>
  filesErrorCase(
    {
      setErrorMessage: (data) => {
        toast.system('', { key: 'upload-files-failed', data });
      }
    },
    { key, params }
  );

const attachFilesFromStorage =
  (chatId) => (dispatch, getState) => async (fileIds) =>
    attachFilesFromStorageCase(
      {
        getChatInput: applyState(getState, getters.getChatInput),
        errorHandler: filesError,
        attachUnsentFileFromStorage: storage.attachUnsentFileFromStorage,
        getUnsentFiles: storage.getUnsentFiles,
        setInputFiles: bindActionCreators(actions.setInputFiles, dispatch),
        setFocusOnInputMessage
      },
      { chatId, fileIds }
    );

export const attachFilesFromStorageModal = (chatId) => (dispatch) =>
  attachFilesFromStorageModalCase(
    {
      showModal: bindActionCreators(showModal, dispatch),
      attachFilesFromStorage: bindActionCreators(
        attachFilesFromStorage,
        dispatch
      )
    },
    { chatId }
  );

export const attachLocalFiles = (chatId, files) => async (dispatch, getState) =>
  attachLocalFilesCase(
    {
      getOpenedChatId: applyState(getState, cwGetters.maybeGetOpenedChatId),
      getChatInput: applyState(getState, getters.getChatInput),
      setInputLoadingFilesIds: bindActionCreators(
        actions.setInputLoadingFilesIds,
        dispatch
      ),
      setInfoMessage: (data) =>
        toast.system('', { key: 'upload-files-processing', data }),
      setSuccessMessage: (data) =>
        toast.system('', { key: 'upload-files-success', data }),
      setErrorMessage: (data) =>
        toast.system('', { key: 'upload-files-failed', data }),
      attachUnsentFile: storage.attachUnsentFile,
      sendWaitingMessages: bindActionCreators(
        waitingMessagesCases.sendWaitingMessages,
        dispatch
      ),
      errorHandler: bindActionCreators(filesError, dispatch),
      addFileToMessage: bindActionCreators(
        waitingMessagesActions.addFileToMessage,
        dispatch
      ),
      removeFileId: bindActionCreators(
        waitingMessagesActions.removeFileId,
        dispatch
      ),
      getUnsentFiles: storage.getUnsentFiles,
      setInputFiles: bindActionCreators(actions.setInputFiles, dispatch),
      setFocusOnInputMessage,
      removeInputLoadingFileId: bindActionCreators(
        actions.removeInputLoadingFileId,
        dispatch
      ),
      getWaitingMessages: applyState(
        getState,
        waitingMessagesGetters.getWaitingMessages
      ),
      goToFileStorage: () => customHistory.push('/file-storage/chat'),
      filesLoadHandler
    },
    { chatId, files }
  );

export const removeUnsentFile = (chatId, file) => async (dispatch, getState) =>
  removeUnsentFileCase(
    {
      getEditMsg: applyState(getState, getters.getEditMsg),
      getChatInput: applyState(getState, getters.getChatInput),
      setInputFiles: bindActionCreators(actions.setInputFiles, dispatch),
      removeUnsentFile: storage.removeUnsentFile,
      getUnsentFiles: storage.getUnsentFiles
    },
    { chatId, file }
  );

export const changeText = (text) => (dispatch, getState) =>
  changeTextCase(
    {
      getChatInputText: applyState(getState, getters.getChatInputText),
      setInputText: bindActionCreators(actions.setInputText, dispatch)
    },
    { text }
  );

export const editMessage = (chatId) => (dispatch, getState) =>
  editMessageCase(
    {
      getChatMsgs: applyState(getState, messagesGetters.getChatMsgs),
      getCurrentEmployeeId: applyState(
        getState,
        commonGetters.getCurrentEmployeeId
      ),
      editMessage: bindActionCreators(messagesCases.editMessage, dispatch)
    },
    { chatId }
  );
