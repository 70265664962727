import { useCallback, useEffect, useMemo, useState } from 'react';
import cn from 'classnames';
import {
  createTheme,
  StyledEngineProvider,
  ThemeProvider
} from '@mui/material/styles';
import { Select, MenuItem, SelectChangeEvent, MenuProps } from '@mui/material';

import { Typography } from '@/components/Typography';
import { Checkbox } from '@/components/Checkbox';
import { Icon } from '@/components/Icon';

import { noop } from '@/lib/utils';
import {
  ArrowProps,
  InputComponentProps,
  MultiSelectProps,
  MultiSelectOption
} from './MultiSelect.types';

import styles from './MultiSelect.module.scss';

function Arrow({ className, size = 18 }: ArrowProps) {
  return (
    <div className={cn(styles.arrow, className)} role="presentation">
      <Icon iconName="arrow-down-select" width={size} height={size} />
    </div>
  );
}

function InputTextComponent({ value }: InputComponentProps) {
  return (
    <Typography className={styles.inputText} variant="body1Reg">
      {value}
    </Typography>
  );
}

const theme = createTheme({
  components: {
    MuiButtonBase: {
      defaultProps: {
        disableRipple: true
      }
    }
  }
});

const menuProps: Partial<MenuProps> = {
  className: styles.menu
};

export function MultiSelect<T extends string | number>({
  values,
  options,
  onSelect,
  className,
  noSelectText,
  defaultSelected,
  valid = true,
  errorText,
  testId
}: MultiSelectProps<T>) {
  const isControlled = !!values;

  const [selected, setSelected] = useState<MultiSelectOption<T>[]>(
    defaultSelected && !isControlled ? defaultSelected : []
  );

  const optionsMappedToValue = useMemo(
    () =>
      options.reduce(
        (optionsMap, currentOption) => ({
          ...optionsMap,
          [currentOption.value]: currentOption
        }),
        {} as Record<T, MultiSelectOption<T>>
      ),
    [options]
  );

  const handleSelect = useCallback(
    (event: SelectChangeEvent<MultiSelectOption<T>[]>) => {
      const selectedOptions = event.target.value as MultiSelectOption<T>[];
      if (!isControlled) {
        setSelected(selectedOptions);
      }
      onSelect(selectedOptions.map((selectedOption) => selectedOption.value));
    },
    []
  );

  useEffect(() => {
    if (isControlled) {
      setSelected(values.map((value) => optionsMappedToValue[value]));
    }
  }, [values]);

  const renderArrow = useCallback(
    (props: { className: string }) => <Arrow className={props.className} />,
    []
  );

  const renderValue = useCallback(
    (selectedOptions: MultiSelectOption<T>[]) => {
      if (!selectedOptions.length && noSelectText) {
        return <InputTextComponent value={noSelectText} />;
      }
      return (
        <InputTextComponent
          value={selectedOptions.map((option) => option.label).join(', ')}
        />
      );
    },
    [noSelectText]
  );

  return (
    <div className={styles.container}>
      <div
        className={cn(styles.multiSelect, !valid && styles.error, className)}>
        <ThemeProvider theme={theme}>
          <StyledEngineProvider injectFirst>
            <Select
              multiple
              value={selected}
              onChange={handleSelect}
              renderValue={renderValue}
              IconComponent={renderArrow}
              MenuProps={menuProps}
              data-testId={testId}
              displayEmpty>
              {options.map((option) => (
                // @ts-ignore
                <MenuItem
                  disableRipple
                  className={styles.menuItem}
                  key={option.value}
                  value={option}
                  dense>
                  <Checkbox
                    checked={selected.some(
                      (selectedOption) => selectedOption.value === option.value
                    )}
                    onChange={noop}
                  />
                  <Typography variant="body1Reg">{option.label}</Typography>
                </MenuItem>
              ))}
            </Select>
          </StyledEngineProvider>
        </ThemeProvider>
      </div>

      {!valid && errorText && (
        <Typography variant="caption" className={styles.errorMessage}>
          {errorText}
        </Typography>
      )}
    </div>
  );
}
