import { PropsWithChildren, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { getIsUserUploaded, getPlainCurrentUser } from '@/storeGetters';
import { HINTS, hintService, newFeaturesService, Roles } from '@/api';
import appConfig from '@/config/app';
import { isEmpty, isNil } from 'ramda';
import { useNewFeaturesPopup } from '@/components/popups';
import { useNotificationContext } from '@/components/notification';
import { Id, Nullable } from '@/types';

interface RootProps extends PropsWithChildren {}

function getNotificationVisibility(
  companyPackages: {
    id?: Id;
    name: string;
    price: Nullable<number>;
    trial: boolean;
    expirationAt: Nullable<Date>;
    active: boolean;
  }[],
  isCustomer: boolean,
  isSupplier: boolean
) {
  const hybrid = isCustomer && isSupplier;

  if ((!hybrid && isSupplier) || isEmpty(companyPackages)) {
    return false;
  }

  return !companyPackages.some((pack) => pack.active);
}
export function Root(props: RootProps) {
  const { children } = props;

  const user = useSelector(getPlainCurrentUser);
  const isUploaded = useSelector(getIsUserUploaded);
  const [showNewFeaturesPopup] = useNewFeaturesPopup();

  const { currentCompany, company } = user;

  const { setIsVisible, setIsTrial } = useNotificationContext();

  useEffect(() => {
    async function processingHints() {
      if (!isUploaded) return;

      const hintsNameToRequest = [HINTS.NewFeatures];

      const hints = await hintService.getUserHints(hintsNameToRequest);

      hints.forEach((hint) => {
        switch (hint.name) {
          case HINTS.NewFeatures:
            if (!hint.viewed) {
              const roles = [];
              if (user.isCustomer) roles.push(Roles.Customer);
              if (user.isSupplier) roles.push(Roles.Supplier);

              const newFeatures =
                newFeaturesService.getNewFeaturesByVersionAndRoles(
                  appConfig.version,
                  roles
                );

              if (!isEmpty(newFeatures)) {
                showNewFeaturesPopup({});
              }
            }
            break;
          default:
            break;
        }
      });
    }

    function defineVisibilityAndContent() {
      if (!isUploaded || isNil(company?.packages)) {
        return;
      }

      const visibility = getNotificationVisibility(
        company.packages,
        user.isCustomer,
        user.isSupplier
      );
      setIsVisible(visibility);
      const trial = company.packages.every((pack) => pack.trial);
      setIsTrial(trial);
    }

    processingHints();
    defineVisibilityAndContent();
  }, [isUploaded, currentCompany, company]);

  return children;
}
