import React from 'react';
import { Navigate, Outlet } from 'react-router-dom';
import { useConfig } from '@/ConfigProvider';

export const ProtectedRoute = ({
  isAllowed = false,
  redirectPath: redirectPathProp = null,
  children
}) => {
  const [config] = useConfig();

  const redirectPath = redirectPathProp ?? config.noAccessPath;

  if (!isAllowed) {
    return <Navigate to={redirectPath} replace />;
  }

  return children ?? <Outlet />;
};
