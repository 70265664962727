import { Route, Routes } from 'react-router-dom';
import React from 'react';
import { usePermissions } from '@/modules/permissions';
import { ProtectedRoute } from '@/components/PrivateRoute';
import {
  CreateNeed,
  EditNeed,
  NeedView,
  Needs,
  NeedAcceptance
} from '@/pages/needs';

export const NeedsRoutes = () => {
  const permissions = usePermissions();

  const haveNeedsReadPermissions = permissions.haveNeedsReadPermissions();
  const haveNeedsEditPermissions = permissions.haveNeedsEditPermissions();
  const hasNeedsAcceptancePermissions =
    permissions.hasNeedsAcceptancePermissions();

  return (
    <ProtectedRoute
      isAllowed={
        haveNeedsReadPermissions ||
        haveNeedsEditPermissions ||
        hasNeedsAcceptancePermissions
      }>
      <Routes>
        <Route
          path="/create"
          element={
            <ProtectedRoute isAllowed={haveNeedsReadPermissions}>
              <CreateNeed />
            </ProtectedRoute>
          }
        />
        <Route
          path="/:needId/edit"
          element={
            <ProtectedRoute
              isAllowed={haveNeedsReadPermissions || haveNeedsEditPermissions}>
              <EditNeed />
            </ProtectedRoute>
          }
        />
        <Route
          path="/:needId/view"
          element={
            <ProtectedRoute
              isAllowed={
                haveNeedsReadPermissions ||
                haveNeedsEditPermissions ||
                hasNeedsAcceptancePermissions
              }>
              <NeedView />
            </ProtectedRoute>
          }
        />
        <Route
          path="/:needId/acceptance"
          element={
            <ProtectedRoute isAllowed={hasNeedsAcceptancePermissions}>
              <NeedAcceptance />
            </ProtectedRoute>
          }
        />
        <Route path="/" element={<Needs />} />
      </Routes>
    </ProtectedRoute>
  );
};
