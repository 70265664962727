import { useCallback, useMemo } from 'react';
import cn from 'classnames';
import { Chip as MuiChip, ChipClasses as MUIChipClasses } from '@mui/material';
import { isFunction } from '@/lib/utils';
import { Typography } from '../Typography';
import { ButtonIcon } from '../ButtonIcon';
import { ChipProps, LabelProps } from './chip.types';
import styles from './chip.module.scss';

function Label({
  label,
  onDelete,
  id,
  limitedWidth,
  error,
  deletable = true,
  textClassname
}: LabelProps) {
  const handleDelete = useCallback(() => {
    if (isFunction(onDelete)) {
      onDelete(id);
    }
  }, [id, onDelete]);

  return (
    <div className={styles.content}>
      <Typography
        variant="body1Reg"
        noWrap={false}
        className={cn(
          limitedWidth ? styles.labelTextLimited : styles.labelText,
          error && styles.errorLabelText,
          textClassname
        )}>
        {label}
      </Typography>
      {deletable && (
        <ButtonIcon
          iconName="close"
          variant="extraSmall"
          onClick={handleDelete}
          className={cn(styles.deleteButton, error && styles.errorDeleteButton)}
          stopPropagation
        />
      )}
    </div>
  );
}

export function Chip({
  label,
  id,
  onDelete,
  limitedWidth,
  error,
  deletable,
  className,
  textClassname
}: ChipProps) {
  const chipClasses: Partial<MUIChipClasses> = useMemo(
    () => ({
      root: cn(styles.root, error && styles.errorRoot, className),
      label: styles.label
    }),
    [error, className]
  );

  return (
    <div className={styles.chip}>
      <MuiChip
        label={
          <Label
            label={label}
            onDelete={onDelete}
            id={id}
            limitedWidth={limitedWidth}
            error={error}
            deletable={deletable}
            textClassname={textClassname}
          />
        }
        classes={chipClasses}
      />
    </div>
  );
}
