.loader {
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 0;
  left: 0;
  background-color: var(--bg-content);
  width: 100%;
  height: 100%;
  z-index: 1;
}

.dot {
  margin: 2px;
  width: 7px;
  height: 7px;
  border-radius: 50%;
  background: #767b92;
  transform: scale(0.5);
  animation: scale-dot 1.2s ease-out infinite;

  &:nth-of-type(2) {
    animation-delay: 0.2s;
  }

  &:nth-of-type(3) {
    animation-delay: 0.3s;
  }
}

@keyframes scale-dot {
  0% {
    transform: scale(0.5);
    opacity: 0.5;
  }

  25% {
    transform: scale(1);
    opacity: 1;
  }

  75% {
    transform: scale(0.5);
    opacity: 0.5;
  }

  100% {
    transform: scale(0.5);
    opacity: 0.5;
  }
}
