import { PUSH_ENDPOINT_KEY } from '../constants';

function getExpiresDate() {
  const now = new Date();
  const expires = now.setFullYear(now.getFullYear() + 5);
  return new Date(expires);
}

export const saveEndpointCase = async ({ getCurrentUserId }) => {
  const registration = await navigator.serviceWorker.ready;
  const subscription = await registration.pushManager.getSubscription();

  if (subscription) {
    const currentUserId = getCurrentUserId();
    document.cookie = `${PUSH_ENDPOINT_KEY}-${currentUserId}=${encodeURIComponent(
      subscription.endpoint
    )}; domain=${
      process.env.REACT_APP_UNICAT_BASE_DOMAIN
    }; Expires=${getExpiresDate()};`;

    document.cookie = `${PUSH_ENDPOINT_KEY}-${currentUserId}=${encodeURIComponent(
      subscription.endpoint
    )}; domain=${process.env.ISTOCK_BASE_DOMAIN}; Expires=${getExpiresDate()};`;
  }
};
