import { SaveDraftPurchaseRequestDto, QuickResponseDto } from '@/DTOs';
import { searchQuery } from '@/utils/searchQuery';
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { PurchaseRequest } from '@/components/PurchaseRequests/entities/PurchaseRequest';
import request from '../lib/request';
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { ConvertedNewRequestTable } from './purchaseRequestsService.types';

export const IncludesOptionKeys = Object.freeze({
  hints: 'hints'
});

class PurchaseRequestsService {
  #baseUrl = '/api/requests';

  async getCategories() {
    return request.get(`${this.#baseUrl}/categories`);
  }

  async convertProposalProductsToJson(requestId, file) {
    return request.sendFile(
      `${this.#baseUrl}/${requestId}/table-products/convert`,
      file
    );
  }

  async convertProcurementProductsToJson(file) {
    return request.sendFile(`${this.#baseUrl}/table-products/convert`, file);
  }

  async exportRequestToExcel(requestId) {
    return request.post(`${this.#baseUrl}/${requestId}/export-to-excel`);
  }

  /**
   @param {string | undefined} requestId
   */
  async exportProcurementFormToExcel(requestId) {
    const query = searchQuery.generate({
      requestId
    });

    return request.get(`${this.#baseUrl}/export-to-excel${query}`);
  }

  /**
   @param {File} file
   @returns {Promise<ConvertedNewRequestTable[]>}
   */
  async convertRequestProductsToJson(file) {
    return request.sendFile(`${this.#baseUrl}/table-products/convert`, file);
  }

  async extendResponseEndDate({ requestId, responseEndDate }) {
    return request.patch(
      `${this.#baseUrl}/${requestId}/extend/responseEndDate`,
      {
        responseEndDate
      }
    );
  }

  async saveDraft(payload) {
    const saveDraftDto = new SaveDraftPurchaseRequestDto(payload);

    return request.post(`${this.#baseUrl}/save`, saveDraftDto);
  }

  async quickResponse(payload) {
    const quickResponseDto = new QuickResponseDto(payload);

    return request.post(`${this.#baseUrl}/quick-response`, quickResponseDto);
  }

  async sendToEmails({ emails, requestId }) {
    return request.post(`${this.#baseUrl}/${requestId}/email/`, { emails });
  }

  /**
   *
   * @param {number} messageId
   * @returns {Promise<void>}
   */
  async sendRemindAboutKp(messageId) {
    return request.post(`${this.#baseUrl}/email/remind`, { messageId });
  }

  /**
   * @param {number} requestId
   * @returns {Promise<import('./purchaseRequestsService.types').AuditLogsGroup[]>}
   */
  async getAuditLog(requestId) {
    return request.get(`${this.#baseUrl}/${requestId}/audit`);
  }

  async getRequestUniqueNumber(requestId) {
    return request.get(`${this.#baseUrl}/${requestId}/unique-number`);
  }

  /**
    @param {string} requestId
    @returns {Promise<PurchaseRequest>}
   */
  async getRequest(requestId, { token, includes } = {}) {
    const query = searchQuery.generate({ token });

    return request.post(`${this.#baseUrl}/one/${requestId}${query}`, {
      includes
    });
  }

  async getCloneRequest(requestId) {
    return request.get(`${this.#baseUrl}/clone/${requestId}`);
  }

  /**
   Fetch all requests
   @param {object} params
   @param {string} params.search - Строка для поиска
   @param {number} params.limit - Максимальное кол-во записей
   @param {number} params.offset
   @param {string} params.order - Вариант сортировки "default"|"lastPublishedDate"
   @param {string} params.direction - Направление сортировки ASC|DESC
   @param {[number]} params.selectedCategoriesId - Фильтрация по категориям
   @param {boolean} params.initialRequest
   @param {[string]} params.showMode - Выбор варианта отображения (если выбрано "bookmark" - фильтрация по избранным заявкам)
   @param {[string]} params.filters - Массив userId ответственных за заявку
   @returns {Promise<Array>}
   */
  async getAllRequests({
    limit = 20,
    offset = 0,
    search = '',
    order = '',
    direction = 'DESC',
    selectedCategoriesId = [],
    showMode = [],
    filters = [],
    lang
  }) {
    const query = searchQuery.generate({
      limit,
      offset,
      search,
      orderBy: order,
      direction,
      selCatsId: selectedCategoriesId,
      showMode,
      lang
    });

    return request.post(`${this.#baseUrl}/all${query}`, { filters });
  }

  /**
   Fetch my requests
   @param {object} params
   @param {string} params.search - Строка для поиска
   @param {number} params.limit - Максимальное кол-во записей
   @param {number} params.offset
   @param {string} params.order
   @param {string} params.direction - Направление сортировки ASC|DESC
   @param {Array} params.selectedCategoriesId - Фильтрация по категориям
   @param {Array} params.selectedStatus
   @param {boolean} params.isArchived
   @param {object} params.filterBy
   @param {[number]} params.filterBy.responsibleUsers - Массив userId ответственных за заявку
   @returns {Promise<Array>}
   */
  async getMyRequests({
    search = '',
    limit = 20,
    offset = 0,
    order,
    direction,
    selectedCategoriesId = [],
    selectedStatus = [],
    selectedResponseStatus = [],
    isArchived = false,
    filterBy = {
      responsibleUsers: []
    }
  } = {}) {
    const query = searchQuery.generate({
      search,
      limit,
      offset,
      orderBy: order,
      direction,
      selCatsId: selectedCategoriesId
    });

    const abortController = new AbortController();

    const cancel = () => abortController.abort();

    const fetchFn = async () =>
      request.post(
        `${this.#baseUrl}/my${query}`,
        {
          selectedStatus,
          selectedResponseStatus,
          isArchived,
          filterBy
        },
        { signal: abortController.signal }
      );

    return [fetchFn, cancel];
  }

  /**
   *
   * @param {number} companyId
   * @returns {Promise<{ id: number; lastName: string; firstName: string; middleName: string }[]>}
   */
  async getAssignedAsResponsible(companyId) {
    return request.get(`${this.#baseUrl}/${companyId}/assigned-as-responsible`);
  }

  async archiveRequest(requestId, isArchived) {
    return request.post(`${this.#baseUrl}/${requestId}/archive`, {
      isArchived
    });
  }

  async view(requestId) {
    return request.patch(`${this.#baseUrl}/${requestId}/view`);
  }

  async finalizeRequest(requestId) {
    return request.post(`${this.#baseUrl}/${requestId}/finalize`);
  }

  async unpublishRequest(requestId) {
    return request.post(`${this.#baseUrl}/${requestId}/unpublish`);
  }

  /**
   * Fetch initial request info for create new request
   * @returns {Promise<{ number: string, systemNumber: string, country: string | null, region: string | null, city: string | null }>}
   */
  async getInitialRequestInfo() {
    return request.get(`${this.#baseUrl}/initial-request-info`);
  }

  /**
   * Fetch procurement request rules
   * @returns {Promise<Rules>}
   */
  async getRules(requestId) {
    return request.get(`${this.#baseUrl}/${requestId}/rules`);
  }

  /**
   * Fetch procurement channel Id
   * @returns {Promise<{id:string}>}
   */
  async createOrJoinProcurementChannel(requestId) {
    return request.post('/api/procurement-channel/join', {
      procurementId: requestId
    });
  }

  /**
   * Fetch procurement channel Id
   * @returns {Promise<{id:string}>}
   */
  async openProcurementChannelByProcurementId(procurementId) {
    return request.get(`/api/procurement-channel/procurement/${procurementId}`);
  }

  /**
   * @returns {Promise<import('@/components/CurrencyEditor/CurrencyEditor.types').ActualBankCurrency[]>}
   */
  async getAvailableCurrencies() {
    return request.get(`${this.#baseUrl}/available-currencies`);
  }

  /**
   * Fetch my procurement specific prices
   * @param {number} procurementId
   * @returns {Promise<import('./purchaseRequestsService.types').GetMySpecificPricesResult>}
   */
  async getMySpecificPrices(procurementId) {
    return request.get(`${this.#baseUrl}/${procurementId}/specific-prices`);
  }

  async publishProcurementStage(id, data) {
    return request.post(`${this.#baseUrl}/publish-stage`, data);
  }

  async getMyProcurement(id) {
    return request.get(`${this.#baseUrl}/my/${id}`);
  }
}

export const purchaseRequestService = new PurchaseRequestsService();
